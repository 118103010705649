import { EventBus } from '@/plugins/eventbus.js';
import eventManagerService from "@/services/eventManagerService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

class EventUtil {

    constructor(context, event, race) {
      this.context = context;
      this.service = eventManagerService;
      this.tenant = tenant;  
      this.event = event;
      this.race = race || null;
      this.newLayout = window.$cookies.isKey('newlayout') ? window.$cookies.get('newlayout') === 'true' : true; // default on for everyone now
    }

    isDevelopment() {
      return process.env.NODE_ENV == 'development';
    }

    isFreePlan() {
      if (!this.event) return false;
        return this.event.license === 'FREE';
    }

    isBasicPlan() {
      if (!this.event) return false;
        return this.event.license === 'FREE' || this.event.license === 'BASIC';
    }

    isProPlan() {
      if (!this.event) return false;
      return tenant.id !== 'cofi' || this.event.license === 'PRO';
    }

    hasApp() {
      if (!this.event) return false;
      return tenant.id === 'cofi' || this.event.app_bundle_id;
    }
    hasDeeplinking() {
      if (!this.event) return false;
      return tenant.id === 'cofi' || (this.event.meta && this.event.meta.some(x => x.key === 'firebase.link_domain'));
    }

    supportsGeoFence() {
      if (!this.event || !this.race) return false;
      return this.race.type !== 'EXTERNAL';
    }

    supportsCourseMap() {
      if (!this.event || !this.race) return false;
      return this.race.type !== 'EXTERNAL' && (this.race.scoring == 'DISTANCE'||this.race.scoring == 'ELEVATION'||this.race.scoring == 'TRACK'||this.race.scoring == 'CUSTOM'||this.race.scoring == 'STEPS'||this.race.scoring == 'TIME');
    }

    supportsBadges() {
      if (!this.event || !this.race) return false;
      return this.race.type !== 'EXTERNAL';
    }

    supportsSplits() {
      if (!this.event || !this.race) return false;
      return this.race.type === 'EXTERNAL';
    }

    supportsResultsUpload() {
      if (!this.event || !this.race) return false;
      return this.race.type === 'EXTERNAL' || this.race.type === 'HYBRID';
    }

    promoteResultsUpload() {
      if (!this.event) return false;
      return this.event.type === 'RESULTS' || this.event.type === 'HYBRID';
    }
    
    gettingStartedUrl() {
      return 'https://' + location.host + this.context.$router.resolve({name: 'eventGettingStarted', params: {id: this.event.id}}).href;
    }

    async refresh() {
      if (!this.event) return false;

      const eventId = this.event && this.event.id;
      const raceId = this.race && this.race.id;
      var response = await eventManagerService.get(eventId);
      this.event = response.data;
      this.race = this.event && this.event.races && raceId ? this.event.races.find(x => x.id == raceId) : null;

      await EventBus.$emit('eventmanager-refresh', this);
      return true;
    }

    async requestSave() {
      EventBus.$emit('eventmanager-request-save');
    }
    async save() {
      if (!this.event) return false;

      try {
        var response = await eventManagerService.put(this.event);
        console.log('save response', response);
        this.context.$helpers.toastResponse(this.context, {status: 'OK'}, 'Event saved successfully.');

        // reload data
        const raceId = this.race && this.race.id;
        this.event = response.data;
        this.race = this.event && this.event.races && raceId ? this.event.races.find(x => x.id == raceId) : null;

        await EventBus.$emit('eventmanager-refresh', this);

        return true;
      }
      catch (ex) {
        console.log('Error in saving', ex);
        if (ex.response) {
          this.error = ex.response.data.msg;
        }
        // get a fresh copy to prevent unsaved data from showing
        this.refresh();
      }
      return false;
    }
  }

export default EventUtil;